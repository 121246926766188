<template>
  <div>
    <base-section
      id="portal"
      class="lightgrey"
      space="0"
    >
      <div class="col s12">
        <v-card v-if="companyLoaded">
          <v-card-title>
            <span class="headline">{{ companyData.companyName }} ({{ companyid }})</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="companyData.companyName"
                    label="Company Name"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-select
                    v-model="companyData.companyType"
                    :items="companytypes"
                    item-text="name"
                    item-value="id"
                    label="Company type"
                    outlined
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="companyData.accountExec"
                    label="Account Exec"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="companyData.phone"
                    label="Phone"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="companyData.email"
                    label="Email"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="companyData.address1"
                    label="Address 1"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="companyData.address2"
                    label="Address 2"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="companyData.addresscity"
                    label="City"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="companyData.addressstate"
                    label="State"
                  ></v-text-field>
                </v-col>
                
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="companyData.addresszip"
                    label="Zip"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="companyData.loanrangelow"
                    type="number"
                    label="Loan Range Low"
                  ></v-text-field>
                  -
                  <v-text-field
                    v-model="companyData.loanrangehigh"
                    type="number"
                    label="Loan Range High"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="companyLoaded"
              color="blue darken-1"
              text
              @click="save"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
      <div class="containerwrapper">
        <div class="containercol">&nbsp;</div>
        <div class="containercol">
          <h2>Company Default Conditions</h2>
          <table border="0" cellspacing="0" cellpadding="0" width="100%">
            <tr>
              <th>Condition Name</th>
              <th width="20%">Action</th>
            </tr>
          
            <tr
              v-for="(condition, idx) in companyData.conditions"
              :key="idx"
            >
              <td><span style="font-weight:bold;">{{ condition.name }}</span><br /><span style="padding-left:15px">{{ condition.note }}</span></td>
              <td>
                <button type="button" v-on:click="removeCondition(condition.name)" class="btn cancel btn-flat secondary-content"><i class="material-icons">delete</i></button>
              </td>
            </tr>
          </table>
          <table border="0" cellspacing="0" cellpadding="0" width="100%">
            <tr>
              <td width="40%">
                <v-text-field
                  v-model="newManualCondition"
                  label="Manual Condition"
                ></v-text-field>
              </td>
              <td width="40%">
                <v-text-field
                  v-model="newManualConditionNote"
                  label="Note"
                ></v-text-field>
              </td>
              <td width="20%">
                <button type="button" class="btn cancel btn-flat" v-on:click="addManualCondition">Add</button>
              </td>
            </tr>
          </table>
        </div>
        <div class="containercol">&nbsp;</div>
        <div class="containercol">
          <h2>Available Conditions</h2>
          <table border="0" cellspacing="0" cellpadding="0" width="100%">
            <tr>
              <th>Condition Name</th>
              <th width="20%">Action</th>
            </tr>
            <tr
              v-for="condition in systemconditions"
              :key="condition.name"
            >
              <td><span style="font-weight:bold;">{{ condition.name }}</span><br /><span style="padding-left:15px">{{ condition.note }}</span></td>
              <td>
                <button type="button" v-on:click="addCondition(condition.name, condition.note)" class="btn cancel btn-flat secondary-content"><i class="material-icons">add</i></button>
              </td>
            </tr>
          </table>
        </div>
        <div class="containercol">&nbsp;</div>
      </div>
      <hr>
      <div class="containerwrapper">
        <div class="containercol">&nbsp;</div>
        <div class="containercol">
          <h2>Company Property Types</h2>
          <table border="0" cellspacing="0" cellpadding="0" width="100%">
            <tr>
              <th>Name</th>
              <th width="20%">Action</th>
            </tr>
          
            <tr
              v-for="(propertytype, idx) in companyData.propertytypes"
              :key="idx"
            >
              <td><span style="font-weight:bold;">{{ propertytype.name }}</span></td>
              <td>
                <button type="button" v-on:click="removePropertyType(propertytype.name)" class="btn cancel btn-flat secondary-content"><i class="material-icons">delete</i></button>
              </td>
            </tr>
          </table>
        </div>
        <div class="containercol">&nbsp;</div>
        <div class="containercol">
          <h2>Available Property Types</h2>
          <table border="0" cellspacing="0" cellpadding="0" width="100%">
            <tr>
              <th>Name</th>
              <th width="20%">Action</th>
            </tr>
            <tr
              v-for="propertytype in systempropertytypes"
              :key="propertytype.name"
            >
              <td><span style="font-weight:bold;">{{ propertytype.name }}</span></td>
              <td>
                <button type="button" v-on:click="addPropertyType(propertytype.name)" class="btn cancel btn-flat secondary-content"><i class="material-icons">add</i></button>
              </td>
            </tr>
          </table>
        </div>
        <div class="containercol">&nbsp;</div>
      </div>
      <hr>
      <div class="containerwrapper">
        <div class="containercol">&nbsp;</div>
        <div class="containercol">
          <h2>Company Loan Types</h2>
          <table border="0" cellspacing="0" cellpadding="0" width="100%">
            <tr>
              <th>Name</th>
              <th width="20%">Action</th>
            </tr>
          
            <tr
              v-for="(loantype, idx) in companyData.loantypes"
              :key="idx"
            >
              <td><span style="font-weight:bold;">{{ loantype.name }}</span></td>
              <td>
                <button type="button" v-on:click="removeLoanType(loantype.name)" class="btn cancel btn-flat secondary-content"><i class="material-icons">delete</i></button>
              </td>
            </tr>
          </table>
        </div>
        <div class="containercol">&nbsp;</div>
        <div class="containercol">
          <h2>Available Loan Types</h2>
          <table border="0" cellspacing="0" cellpadding="0" width="100%">
            <tr>
              <th>Name</th>
              <th width="20%">Action</th>
            </tr>
            <tr
              v-for="loantype in systemloantypes"
              :key="loantype.name"
            >
              <td><span style="font-weight:bold;">{{ loantype.name }}</span></td>
              <td>
                <button type="button" v-on:click="addLoanType(loantype.name)" class="btn cancel btn-flat secondary-content"><i class="material-icons">add</i></button>
              </td>
            </tr>
          </table>
        </div>
        <div class="containercol">&nbsp;</div>
      </div>
      <hr>
      <div class="row">
        <div class="col s12">
          <h5>Notes</h5>
          <table border="0" cellspacing="0" cellpadding="0" v-if="companyData.Notes" width="100%">
            <tr>
              <th width="20%">Date</th>
              <th width="25%">User</th>
              <th width="55%">Note</th>
            </tr>
            <tr
              v-for="(note, i) in companyData.Notes"
              :key="i"
            >
              <td width="20%">{{note.created | formatDate}}</td>
              <td width="25%">{{userNameById(note.User)}}</td>
              <td width="55%">{{note.Note}}</td>
            </tr>
          </table>
          <table border="0" cellspacing="0" cellpadding="0" width="100%">
            <tr>
              <td width="80%">
                <v-text-field
                  v-model="newnote"
                  label="New Note"
                ></v-text-field>
              </td>
              <td width="20%">
                <button type="button" class="btn cancel btn-flat" v-on:click="addNote">Add</button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </base-section>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { Users } from '@/database/users'
  import { Companies } from '@/database/companies'
  import { System } from '@/database/system'
  import { FirebaseResults } from '@/database'

  export default {
    name: 'SectionMap',
    data: () => ({
      companyid: '',
      companyData: {},
      companyLoaded: false,
      companytypes: [],
      conditions: [],
      systemconditions: [],
      allsystemconditions: [],
      propertytypes: [],
      systempropertytypes: [],
      allsystempropertytypes: [],
      loantypes: [],
      systemloantypes: [],
      allsystemloantypes: [],
      users: [],
      newManualCondition: '',
      newManualConditionNote: '',
      newnote: '',
    }),
    provide: {
      heading: { align: 'center' },
    },
    computed: {
      // map `this.user` to `this.$store.getters.user`
      ...mapGetters({
        user: 'user',
      }),
    },
    mounted () {
      this.companyid = this.$route.params.companyid
      this.initialize()
    },
    methods: {
      initialize () {
        System.findOne('companytypes').then(doc => {
          const results = doc.data().data
          this.companytypes = results.map(function(item){
            return { id: item.name, name: item.name }
          })
        })
        Users.findAll().onSnapshot(docs => {
          const results = FirebaseResults.map(docs)
          this.users = results.map(function(item){
            return { id: item.id, name: item.name }
          })
        })
        Companies.findOne(this.companyid).then(doc => {
        this.companyData = doc.data()
        this.companyData.id = doc.id
        console.log(this.companyData)
        if (this.companyData.conditions) {
          this.conditions = this.companyData.conditions
        }
        if (this.companyData.propertytypes) {
          this.propertytypes = this.companyData.propertytypes
        }
        if (this.companyData.loantypes) {
          this.loantypes = this.companyData.loantypes
        }

        System.findOne('conditions').then(doc => {
          this.systemconditions = doc.data().data
          this.allsystemconditions = [...this.systemconditions]
          this.systemconditions.forEach(sc => {
            sc.used = false
          })
          // console.log(this.conditions)
          if (this.conditions.length > 0){
            this.conditions.forEach(c => {
              // mark this selected condition as used
              this.systemconditions.forEach(sc => {
                if (c.name === sc.name){
                  sc.used = true
                }
              })
            })
            var conditionsToDelete = this.conditions.map(function(elt) { return elt.name })
            this.systemconditions = this.systemconditions.filter(function(elt) { return conditionsToDelete.indexOf(elt.name) === -1 })
          }
        })
        System.findOne('propertytypes').then(doc => {
          this.systempropertytypes = doc.data().data
          this.allsystempropertytypes = [...this.systempropertytypes]
          this.systempropertytypes.forEach(sc => {
            sc.used = false
          })
          if (this.propertytypes.length > 0){
            this.propertytypes.forEach(c => {
              // mark this selected condition as used
              this.systempropertytypes.forEach(sc => {
                if (c.name === sc.name){
                  sc.used = true
                }
              })
            })
            var propertytypesToDelete = this.propertytypes.map(function(elt) { return elt.name })
            this.systempropertytypes = this.systempropertytypes.filter(function(elt) { return propertytypesToDelete.indexOf(elt.name) === -1 })
          }
        })
        System.findOne('loantypes').then(doc => {
          this.systemloantypes = doc.data().data
          this.allsystemloantypes = [...this.systemloantypes]
          this.systemloantypes.forEach(sc => {
            sc.used = false
          })
          if (this.loantypes.length > 0){
            this.loantypes.forEach(c => {
              // mark this selected condition as used
              this.systemloantypes.forEach(sc => {
                if (c.name === sc.name){
                  sc.used = true
                }
              })
            })
            var loantypesToDelete = this.loantypes.map(function(elt) { return elt.name })
            this.systemloantypes = this.systemloantypes.filter(function(elt) { return loantypesToDelete.indexOf(elt.name) === -1 })
          }
        })
        this.companyLoaded = true
      })
      },
      runtest () {
        // console.log(this.user.data)
        if (this.usertype === 'borrower'){
          this.usertype = 'loan officer'
        } else {
          this.usertype = 'borrower'
        }
      },
      save () {
        this.updateCompany(false)
      },
      updateCompany (resetConditions) {
        Companies.update(this.companyData.id, this.companyData).then(results => {
          console.log('Updated.')
          if (resetConditions) {
            if (!this.conditions) { this.conditions = [] }
            this.conditions = this.companyData.conditions
            this.systemconditions = [...this.allsystemconditions]
            var conditionsToDelete = this.conditions.map(function(elt) { return elt.name })
            this.systemconditions = this.systemconditions.filter(function(elt) { return conditionsToDelete.indexOf(elt.name) === -1 })

            if (!this.propertytypes) { this.propertytypes = [] }
            this.propertytypes = this.companyData.propertytypes
            this.systempropertytypes = [...this.allsystempropertytypes]
            var propertytypesToDelete = this.propertytypes.map(function(elt) { return elt.name })
            this.systempropertytypes = this.systempropertytypes.filter(function(elt) { return propertytypesToDelete.indexOf(elt.name) === -1 })

            if (!this.loantypes) { this.loantypes = [] }
            this.loantypes = this.companyData.loantypes
            this.systemloantypes = [...this.allsystemloantypes]
            var loantypesToDelete = this.loantypes.map(function(elt) { return elt.name })
            this.systemloantypes = this.systemloantypes.filter(function(elt) { return loantypesToDelete.indexOf(elt.name) === -1 })
          }
        })
      },
      addManualCondition () {
        if (!this.companyData.conditions){
          this.companyData.conditions = []
        }
        // companyData.conditions.push({ name: newManualCondition });
        
        this.companyData.conditions = [...this.companyData.conditions, { name: this.newManualCondition, note: this.newManualConditionNote }]

        this.updateCompany(true)
        this.newManualCondition = ''
        this.newManualConditionNote = ''
      },
      addCondition (conditionname, conditionnote) {
        if (!this.companyData.conditions){
          this.companyData.conditions = []
        }
        // companyData.conditions.push({ name: newManualCondition });
        this.companyData.conditions = [...this.companyData.conditions, { name: conditionname, note: conditionnote }]

        this.updateCompany(true)
      },
      removeCondition (conditionname) {
        // console.log(this.companyData.conditions)
        this.companyData.conditions = this.companyData.conditions.filter(val => val.name !== conditionname)
        this.updateCompany(true)
      },
      addPropertyType (propertytypename) {
        if (!this.companyData.propertytypes){
          this.companyData.propertytypes = []
        }
        // companyData.conditions.push({ name: newManualCondition });
        this.companyData.propertytypes = [...this.companyData.propertytypes, { name: propertytypename }]

        this.updateCompany(true)
      },
      removePropertyType (propertytypename) {
        // console.log(this.companyData.conditions)
        this.companyData.propertytypes = this.companyData.propertytypes.filter(val => val.name !== propertytypename)
        this.updateCompany(true)
      },
      addLoanType (loantypename) {
        if (!this.companyData.loantypes){
          this.companyData.loantypes = []
        }
        // companyData.conditions.push({ name: newManualCondition });
        this.companyData.loantypes = [...this.companyData.loantypes, { name: loantypename }]

        this.updateCompany(true)
      },
      removeLoanType (loantypename) {
        // console.log(this.companyData.conditions)
        this.companyData.loantypes = this.companyData.loantypes.filter(val => val.name !== loantypename)
        this.updateCompany(true)
      },
      addNote () {
        if (!this.companyData.Notes) {
          this.companyData.Notes = []
        }
        const newNoteObj = {
          User: this.user.data.id,
          Note: this.newnote,
          created: Date(),
        }
        this.companyData.Notes = [...this.companyData.Notes, newNoteObj]
        this.updateCompany(false)
        this.newnote = ''
      },
      userNameById (matchUID) {
        var match = this.users.filter(item => item.id === matchUID)
          if (match.length > 0){
          return match[0].name
        } else {
          return 'Unable to Find User'
        }
      },
    },
  }
</script>
<style scoped>
  .containerwrapper {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr 4fr 1fr 4fr 1fr;
  }
  .containercol {
    text-align: left;
    font-size: small;
    line-height: 34px;
  }
</style>
